<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 12">
        <v-card
          v-for="(post, index) in getAllPublications"
          :key="index"
          class="pa-4 mb-6 rounded-lg"
        >
          <v-list-item :id="`card${index}`">
            <v-list-item-content>
              <v-list-item-subtitle class="primary--text font-weight-bold">
                Publicado el {{ formatDate(post.createdAt) }}
              </v-list-item-subtitle>
              <span
                class="wc-h5"
                :style="{ color: color.primary, fontSize: '26px' }"
              >
                {{ post.name }}
              </span>
              <v-list-item-subtitle>
                <v-row>
                  <v-col
                    cols="12"
                    v-if="post.type == 'BLOG'"
                    :id="`blogColumn${index}`"
                  >
                    <div>
                      <vue-editor
                        :ref="`vueEditor${index}`"
                        :id="`vueEditor${index}`"
                        :class="post.expand === true || 'editor'"
                        :editorToolbar="['']"
                        :editorOptions="{ theme: '', readOnly: true }"
                        v-model="post.value"
                      >
                      </vue-editor>
                    </div>
                    <div class="d-flex justify-end">
                      <v-btn
                        @click="setClass(post)"
                        :herf="`vueEditor${index}`"
                        class="white--text text-capitalize font-weight-bold rounded-xl"
                        elevation="0"
                        :color="color.primary"
                      >
                        <span v-if="!post.expand"
                          >Leer más <v-icon>mdi-arrow-down</v-icon></span
                        >
                        <span v-else
                          >Leer menos <v-icon>mdi-arrow-up</v-icon></span
                        >
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    v-else-if="post.type == 'VIDEO'"
                    class="d-flex justify-center"
                  >
                    <iframe
                      :src="youtubeCut(post.value)"
                      frameborder="0"
                      width="600"
                      height="302"
                    ></iframe>
                  </v-col>

                  <v-col cols="12" v-else-if="post.type == 'DOC'" class="mt-3">
                    <embed
                      v-if="
                        post.url.includes('pdf') ||
                        (post.url.includes('blob') &&
                          post.value.type.includes('pdf'))
                      "
                      width="100%"
                      type="application/pdf"
                      height="650"
                      :src="
                        post.url.includes('blob')
                          ? post.url
                          : 'https://drive.google.com/viewerng/viewer?embedded=true&url=' +
                            post.url
                      "
                    />
                    <v-img
                      v-else
                      max-height="400"
                      contain
                      :src="post.url"
                      alt="content"
                    />
                    <div class="d-flex align-center justify-end">
                      <v-btn
                        class="rounded-xl mt-3 text-capitalize font-weight-bold d-flex align-center"
                        elevation="0"
                        :href="post.url"
                        :disabled="post.url.includes('blob')"
                        :color="color.secondary"
                      >
                        <v-icon>mdi-download</v-icon> Descargar
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col cols="12" v-else-if="post.type == 'LINK'">
                    <v-sheet
                      class="py-3 px-4 mt-3 rounded-lg d-flex align-center justify-start pointer"
                      color="#ebecef"
                      @click="window.open(post.value, '_blank')"
                    >
                      <v-icon :color="color.primary" class="mr-4"
                        >mdi-link</v-icon
                      >
                      <a> {{ post.value }} </a>
                    </v-sheet>
                  </v-col>

                  <v-col cols="12" class="mt-4 d-flex flex-column">
                    <p class="ma-0 mb-1">
                      {{ post.description }}
                    </p>
                    <p class="ma-0 mb-1">
                      <span class="tag" v-for="(tag, i) in post.labels" :key="i"
                        >#{{ tag }}
                      </span>
                    </p>
                    <!-- <p>
                      <span class="font-weight-bold">Autor: </span
                      >{{ post.owner.name + " " + post.owner.firstLastname }}
                    </p> -->
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
              <!--
              <v-list-item-subtitle>
                <span class="coments-views mr-3"
                  >0<v-icon color="#3aa4c6" class="ml-1"
                    >mdi-message-outline</v-icon
                  ></span
                >
                <span class="coments-views mr-3"
                  >0<v-icon color="#3aa4c6" class="ml-1"
                    >mdi-eye-outline</v-icon
                  ></span
                >
              </v-list-item-subtitle>-->
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { colors } from "@/constants/colors.js";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  props: {
    posts: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      color: colors,
      handleCreate: false,
    };
  },

  methods: {
    formatDate(currentDate) {
      const date = new Date(currentDate);
      const dateOptions = { day: "numeric", month: "short", year: "numeric" };
      const timeOptions = { hour: "numeric", minute: "numeric" };
      let fullDate = date
        .toLocaleDateString("es-MX", dateOptions)
        .replaceAll(" ", "-")
        .toUpperCase();
      let fullTime = date
        .toLocaleTimeString("en-MX", timeOptions)
        .toUpperCase();
      return `${fullDate} - ${fullTime}`;
    },

    /**
     * Recibe el indice de la entrada, cambia la clase 'editor' la cual
     * expande o comprime la publicación de tipo 'BLOG'
     * @param {Object} postExpanded
     */
    setClass(postExpanded) {
      let i = this.posts.findIndex((post) => post._id === postExpanded._id);
      if (postExpanded.expand == false) {
        this.$emit("changePosts", {
          property: "expand",
          value: true,
          index: i,
        });
      } else {
        this.$emit("changePosts", {
          property: "expand",
          value: false,
          index: i,
        }),
          document
            .getElementById(`card${i}`)
            .scrollIntoView({ behavior: "smooth" });
      }
    },

    setStyle(i) {
      return this.posts[i].expand;
    },

    youtubeCut(youtube) {
      let youtubeId = "";

      if (youtube.startsWith("https://youtu.be/")) {
        youtubeId = youtube.split("https://youtu.be/")[1];
      } else {
        if (youtube.includes("?v=")) {
          youtubeId = youtube.split("?v=")[1];
        } else if (youtube.includes("&v=")) {
          youtubeId = youtube.split("&v=")[1];
        }
      }
      youtubeId = youtubeId.split("&")[0];
      return `https://www.youtube.com/embed/${youtubeId}`;
    },
  },

  updated() {
    this.$nextTick(function () {
      /**
       * Determinar si se agrega los botones para mostrar más / mostrar menos
       *  handleCreate valida que este proceso solo se haga una vez
       */
      if (this.handleCreate === false) {
        for (let i = 0; i <= this.posts.length; i++) {
          if (this.$refs[`vueEditor${i}`]) {
            // si el tamaño del editor es mayor al tamaño de v-card, muestra el botón, de lo contrario lo oculta
            if (
              this.$refs[`vueEditor${i}`][0].$el.scrollHeight >
              this.$refs[`vueEditor${i}`][0].$el.clientHeight
            ) {
              this.$emit("changePosts", {
                property: "hideOver",
                value: true,
                index: i,
              });
              // this.posts[i].hideOver = true
            } else {
              this.$refs[`vueEditor${i}`][0].$el.classList.remove("editor");
              this.$emit("changePosts", {
                property: "hideOver",
                value: false,
                index: i,
              });
              // this.posts[i].hideOver = false
            }
          }
        }
        this.handleCreate = true;
      }
    });
  },
  computed: {
    getAllPublications() {
      return this.posts;
    },
  },
};
</script>
<style scoped>
.tag {
  color: #1b273e;
  margin-inline-end: 6px;
  text-decoration: underline;
  font-weight: 550;
}

.editor {
  position: relative;
  overflow: hidden;
  max-height: 217px;
}

.editor:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.coments-views {
  color: #3aa4c6;
  font-weight: 500;
}
</style>