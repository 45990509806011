var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 12}},_vm._l((_vm.getAllPublications),function(post,index){return _c('v-card',{key:index,staticClass:"pa-4 mb-6 rounded-lg"},[_c('v-list-item',{attrs:{"id":`card${index}`}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"primary--text font-weight-bold"},[_vm._v(" Publicado el "+_vm._s(_vm.formatDate(post.createdAt))+" ")]),_c('span',{staticClass:"wc-h5",style:({ color: _vm.color.primary, fontSize: '26px' })},[_vm._v(" "+_vm._s(post.name)+" ")]),_c('v-list-item-subtitle',[_c('v-row',[(post.type == 'BLOG')?_c('v-col',{attrs:{"cols":"12","id":`blogColumn${index}`}},[_c('div',[_c('vue-editor',{ref:`vueEditor${index}`,refInFor:true,class:post.expand === true || 'editor',attrs:{"id":`vueEditor${index}`,"editorToolbar":[''],"editorOptions":{ theme: '', readOnly: true }},model:{value:(post.value),callback:function ($$v) {_vm.$set(post, "value", $$v)},expression:"post.value"}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"white--text text-capitalize font-weight-bold rounded-xl",attrs:{"herf":`vueEditor${index}`,"elevation":"0","color":_vm.color.primary},on:{"click":function($event){return _vm.setClass(post)}}},[(!post.expand)?_c('span',[_vm._v("Leer más "),_c('v-icon',[_vm._v("mdi-arrow-down")])],1):_c('span',[_vm._v("Leer menos "),_c('v-icon',[_vm._v("mdi-arrow-up")])],1)])],1)]):(post.type == 'VIDEO')?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('iframe',{attrs:{"src":_vm.youtubeCut(post.value),"frameborder":"0","width":"600","height":"302"}})]):(post.type == 'DOC')?_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[(
                      post.url.includes('pdf') ||
                      (post.url.includes('blob') &&
                        post.value.type.includes('pdf'))
                    )?_c('embed',{attrs:{"width":"100%","type":"application/pdf","height":"650","src":post.url.includes('blob')
                        ? post.url
                        : 'https://drive.google.com/viewerng/viewer?embedded=true&url=' +
                          post.url}}):_c('v-img',{attrs:{"max-height":"400","contain":"","src":post.url,"alt":"content"}}),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"rounded-xl mt-3 text-capitalize font-weight-bold d-flex align-center",attrs:{"elevation":"0","href":post.url,"disabled":post.url.includes('blob'),"color":_vm.color.secondary}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Descargar ")],1)],1)],1):(post.type == 'LINK')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"py-3 px-4 mt-3 rounded-lg d-flex align-center justify-start pointer",attrs:{"color":"#ebecef"},on:{"click":function($event){return _vm.window.open(post.value, '_blank')}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":_vm.color.primary}},[_vm._v("mdi-link")]),_c('a',[_vm._v(" "+_vm._s(post.value)+" ")])],1)],1):_vm._e(),_c('v-col',{staticClass:"mt-4 d-flex flex-column",attrs:{"cols":"12"}},[_c('p',{staticClass:"ma-0 mb-1"},[_vm._v(" "+_vm._s(post.description)+" ")]),_c('p',{staticClass:"ma-0 mb-1"},_vm._l((post.labels),function(tag,i){return _c('span',{key:i,staticClass:"tag"},[_vm._v("#"+_vm._s(tag)+" ")])}),0)])],1)],1)],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }